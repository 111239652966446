// I18nContext.tsx
import { ReactNode, createContext, useContext, useState } from "react";
import i18n from "./i18n";

interface I18nContextType {
  language: string;
  changeLanguage: (lng: string) => void;
}

const I18nContext = createContext<I18nContextType>({
  language: "en",
  changeLanguage: () => {},
});

export const I18nProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <I18nContext.Provider value={{ language, changeLanguage }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => useContext(I18nContext);
